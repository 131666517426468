import React from "react";
import Article from "../common/Article";

const Logout = (props) => {

  return (
    <>
      <div className="login">
      <Article className="content" login={props.login}>


        <form className="login__form">

          <div className="login__form__inner">

            <div className="notice">
              <h3>LOGOUT</h3>
              <p>You are successfully logged out. See you again!</p>
              {props.children}
            </div>

          </div>

        </form>

      </Article>
      </div>
    </>
  );
}

export default Logout;
