import React from "react";
import { startTimeFormat, durationFormat, getClipSequence } from "../../../lib/util";

const ClipBlockInfo = (props) => {
  const { clip } = props;

  return (
    <>
      { !props?.no_seq &&
      <div className="serial">{getClipSequence(clip)}</div>
        }
      <div className="block__info str"><strong>STR</strong><span>{startTimeFormat(clip.start_datetime)}</span></div>
      <div className="block__info dur">
        <strong>DUR</strong><span>{durationFormat(clip.duration_ms)}<small>s</small></span></div>
      <div className="block__info lap"><strong>LAP</strong><span>{clip.lap || "--"}</span></div>
    </>
  )
};
export default ClipBlockInfo;
