import React from "react";
import axios from "axios";
import LoginForm from "./LoginForm";
import MfaForm from "./MfaForm";
import ForgotPassword from "./ForgotPassword";
import ForgotRequestComplete from "./ForgotRequestComplete";
import NewPassword from "./NewPassword";
import App from "../../App";
import { apiGet, apiPost } from "../common/API";

class LoginController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'login',
      formStatus: {},
      login: null,
      form: {
        loginId: '',
        password: '',
        mfaToken: '',
        newPassword: '',
      }
    };
  }

  componentDidMount() {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    let q = params.get("q")
    if (q) {
      if (q === "123") {
        this.setState({mode: 'newPassword'});
      } else {
        this.setState({mode: 'passwordRequestError'});
      }
    }
    // this.loginSuccess()
  }

  changeForm(newValues) {
    const form = Object.assign({}, this.state.form);
    Object.keys(form).forEach((k, v) => {
      if (newValues.hasOwnProperty(k)) {
        form[k] = newValues[k];
      }
    })
    this.setState({form: form});
  }

  login(e) {
    e.preventDefault();
    const {form} = this.state;
    const formStatus = {};

    if (form.loginId == "") {
      formStatus['loginId'] = {
        status: 'invalid',
        message: "Please fill your user ID."
      }
    }
    if (form.password == "") {
      formStatus['password'] = {
        status: 'invalid',
        message: "Please fill your password."
      }
    }

    if (formStatus.loginId || formStatus.password) {
      this.setState({formStatus: formStatus});
      return false;
    }
    const login = {
      login_id: form.loginId,
      password: form.password,
    };
    apiPost("/login", login).then(res => {
      console.log(res);
      if (res.data.result) {
        this.setState({mode: 'mfa', formStatus:{}});
      } else {
        this.setState({formStatus: {"password": { status: 'invalid', message: "Incorrect user ID or password."}}});
      }
    });


   // this.loginSuccess();
    return false;
  }

  clearFormError() {
      this.setState({formStatus: {}});
  }

  mfaLogin(e) {
    const {form} = this.state;
    const formStatus = {};
    e.preventDefault();

    if (form.mfaToken == "") {
      formStatus['mfaToken'] = {
        status: 'invalid',
        message: "Please fill your verification code."
      }
    }

    if (formStatus.mfaToken) {
      this.setState({formStatus: formStatus});
      return false;
    }

    const login = {
      login_id: form.loginId,
      mfa_token: form.mfaToken,
    };
    apiPost("/mfa_login", login).then(res => {
      if (res.data.result) {
        this.loginSuccess(res.data.user);
      } else {
        this.setState({formStatus: {"mfaToken": { status: 'invalid', message: "Incorrect verification code."}}});
      }
    });

  }

  loginSuccess(user) {
    // api
    // console.log('success');
    // const user = {login_id: "haraikawa@reno-cre.com", username: "TAKEHARU HARAIKAWA"};
    console.log(this.props);
    this.props.login(user);
  }

  forgotPassword(e) {
    e.preventDefault();
    this.setState({mode: 'forgotPassword'});
  }

  forgotPasswordRequest(e) {
    e.preventDefault();
    // Request
    this.setState({mode: 'forgotRequestComplete'});
  }

  newPasswordRequest(e) {
    e.preventDefault();
    // Request
    this.setState({mode: 'newPasswordComplete'});
  }

  loginForm(e) {
    e.preventDefault();
    this.setState({mode: 'login'});
  }

  render() {
    const {mode, form, formStatus} = this.state;
    switch (mode) {
      case 'login':
        return <LoginForm form={form}
                          formStatus={formStatus}
                          handleClearError={() => { this.clearFormError(); }}
                          handleChange={newValues => {this.changeForm(newValues);}}
                          handleLogin={e => this.login(e)}
                          handleForgotPassword={e => this.forgotPassword(e)}/>
      case 'mfa':
        return <MfaForm
          form={form}
          formStatus={formStatus}
          handleClearError={() => { this.clearFormError(); }}
          handleChange={newValues => {this.changeForm(newValues);}}
          handleLogin={e => this.mfaLogin(e)}
          handleSendToken={e => this.login(e) }
        />

      case 'forgotPassword':
        return <ForgotPassword
          formStatus={formStatus}
          handleLoginForm={e => {
           this.loginForm(e)
        }}/>

      case 'forgotRequestComplete':
        return <ForgotRequestComplete/>

      case 'newPassword':
        return <NewPassword {...this.props}
                    formStatus={formStatus}
                    handleSubmit={e => this.newPasswordRequest(e) }/>

      case 'passwordRequestError':
        return <passwordRequestError />

      default:
        return null;
    }
  }
}

export default LoginController;
