import React from 'react';

import ArchiveClip from "../common/ArchiveClip";

class EditTextModal extends React.Component {

  componentDidMount() {
  }

  render() {

    return (
      <>
        <article className="modal modal-alert">
          <section className="modal__window">
            <div className="modal__window__inner">
              <div className="modal__close">
                <button className="t-hover" onClick={this.props.handleClose}><span></span><span></span></button>
              </div>
              <h3 className="headline">EDIT</h3>
              <div className="alert-info">
                <form className="edit">
                  <ArchiveClip clip={this.props.editClip} {...this.props} disableEdit={true}/>
                  <div className="edit__form">
                    <div className="input-group ">
                      <label htmlFor="login-password" className="form-label">EDIT AUDIO-TO-TEXT</label>
                      <textarea rows="5" className="form-control" placeholder="INPUT AUDIO-TO-TEXT" value={this.props.editText}
                                onChange={e => { this.props.handleChangeText(e.target.value)}}/>
                      <div className="valid-feedback">ERROR XXXXXXXXXXXXXXXXX</div>
                    </div>
                  </div>

                  <nav className="alert-info__button-area">
                    <div className="row g-4 justify-content-center">
                      <div className="col-auto">
                        <button type="button" className="basic-button button t-hover" onClick={this.props.handleClose}>
                          <span>CANCEL</span></button>
                      </div>
                      <div className="col-auto">
                        <button type="button" className="basic-button submit-button t-hover"
                                onClick={this.props.handleSaveText}><span>SAVE</span></button>
                      </div>
                    </div>
                  </nav>
                </form>
              </div>
            </div>
          </section>
        </article>
      </>
    );
  }
}

export default EditTextModal;
