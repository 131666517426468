import React from "react";
import {durationFormat, startTimeFormat} from "../../../lib/util";

const CustomClip = (props) => {
  return (
    <div className="custom-crip-area">

      <button className={"mini-tab" + (props.customClipFilter ? " on" : "")} id="stock" onClick={() => { props.handleCustomClipFilterChange(true) }}><strong><span>STOCK</span></strong></button>
      <button className={"mini-tab" + (props.customClipFilter ? "" : " on")} id="all" onClick={() => { props.handleCustomClipFilterChange(false) }}><strong><span>ALL CLIPS</span></strong></button>
      <button className="custom-crip-area__return-top" onClick={() => { document.getElementById('customclip-list').scrollTo(0, 0);}}></button>
      <div id="customclip-list" className="custom-crip-area__inner scroll-box-s" style={{scrollBehavior: 'smooth'}}>
        {(
          <>
            {props.myCustomClips.map(clip => {
              const clipClass = ['block'];

              if (props.customClipFilter && (clip.is_tweeted || clip.is_transmitted)) {
                return null;
              }

              if (clip.is_edited) {
                clipClass.push('edited');
              }
              if (clip.is_tweeted) {
                clipClass.push('tweeted');
              }
              if (clip.is_transmitted) {
                clipClass.push('transmited');
              }
              return (
                <button key={clip.customclip_id} className={clipClass.join(" ")}
                        onClick={() => { props.handleEditOperation(clip); }}

                >
                  <div className="block__left">
                    <div className="indicator-wrap">
                      <strong className="num-box"><span>{clip.car_no}</span></strong>
                      <strong className="indicator-box edit"></strong>
                      <strong className="indicator-box twitter"></strong>
                      <strong className="indicator-box transmit"></strong>
                    </div>
                    <h4 className="driver">{clip.driver_name.split(/ /).map((s, i) => <span key={i}>{s.toUpperCase()}</span>)}</h4>
                  </div>
                  <div className="block__right">
                    <div className="block__info str"><strong>STR</strong><span>{startTimeFormat(clip.start_datetime)}</span></div>
                    <div className="block__info dur"><strong>DUR</strong><span>{durationFormat(clip.duration_ms)}<small>s</small></span></div>
                    <div className="block__info lap"><strong>LAP</strong><span>{clip.lap || "--"}</span></div>
                  </div>
                </button>
              )
            })}

          </>
        )}
      </div>
    </div>
  )
};
export default CustomClip;
