import React from "react";
import {format} from "date-fns";

const ArchiveCustomClip = (props) => {
  const { clip } = props;
  if (!clip) {
    return null;
  }

  return (
    <>
      <div className="archive__unit custom">
        <p className="info"><span>CREATED BY&nbsp;:&nbsp;</span>{clip.username.toUpperCase()}<span>TIME&nbsp;:&nbsp;</span>{format(new Date(clip.start_datetime), 'HH:mm:ss.SS')}&nbsp;/&nbsp;
          {clip.duration_ms / 1000}s{clip.lap ? <><span>LAP&nbsp;:&nbsp;</span>{clip.lap}</> : ""}<span>FILE NAME&nbsp;:&nbsp;</span>{clip.filename.replace(/^.+(\/|\\)/, "")}
        </p>
        {clip.modified_text && (
        <p className="text"><span>TEXT&nbsp;:&nbsp;</span>{clip.modified_text}
        </p>

        )}
        {clip.is_finalized && (
        <div className="archive__unit__navi">
          <div className="row align-items-center">
            <div className="col-12 col-md-auto pb-4 pb-md-0">
              <button type="button" className="round-button-s play-button t-hover" onClick={() => { props.handlePlay(clip); }}><span>PLAY</span></button>
            </div>
            {
              ((props.loginUser.is_admin == 1 || props.loginUser.can_download == 1) &&

            <div className="col-auto">
              <button type="button" className="round-button-s red t-hover" onClick={() => { props.handleDownload(clip); }}><span>DOWNLOAD</span></button>
            </div>
              )
            }

            {
              (false && !props.disableEdit && (props.loginUser.is_admin == 1 || props.loginUser.can_edit == 1) &&
                <div className="col-auto">
                  <button className="round-button-s edit t-hover" onClick={() => { console.log(clip); props.handleStartEditText(clip); }}><span>EDIT</span></button>
                </div>

              )
            }
          </div>
        </div>
          )}
      </div>

    </>
  );
}

export default ArchiveCustomClip;
