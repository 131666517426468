import React from "react";
import Article from "../common/Article";
import RequestComplete from "./RequestComplete";

const ForgotRequestComplete = (props) => {

  return (
    <RequestComplete login={props.login}/>
  );
}

export default ForgotRequestComplete;
