import React from "react";
import ClipBlockInfo from "./ClipBlockInfo";
import {SF_YEAR, getEndPoint, getObjectStorageEndPoint} from "../../../lib/util";

const LockOnBlock = (props) => {

  const { clipList, autoPlay, locked, currentClipListIndex, playingClips, timeline, car_no, draggingState, loginUser, content } = props;
  const car = timeline.current.cars.find(car => car.car_no == car_no);
  const clip = clipList.length && clipList[currentClipListIndex];
  const playState = playingClips.hasOwnProperty(clip.autoclip_id) ? playingClips[clip.autoclip_id] : null;
  const playStateStyle = playState ? {display: "block", left: (playState.progress * 100) + '%'} : {display: "none"};

  let driverName = car.driver_name;
  let driverPrefix = "";
  let found;
  if (found = driverName.match(/^(\W+)(.*)/)) {
    driverPrefix = found[1];
    driverName = found[2];
  }
  return (
    <div className="locked-on-area__base">

      <div className={"locked-on-area__unit"
        + (" car-" + car.car_no)
        + (car.capture_status=='terminated' ? " terminate" : "")
        + (autoPlay && playState ? " on-play" : "")
        + (car.team_code ? ' team-' + car.team_code.toLowerCase() : '')
        + (car.engine_code ? ' engine-' + car.engine_code.toLowerCase() : '')        }>
        <div className="locked-on-area__unit__close">
          <button className="t-hover" onClick={() => { props.handleClickDriver(car)}}><span></span><span></span></button>
        </div>

        <div className="headline">
          <div className="headline__driver d-flex align-items-center"><strong
            className="num-box"><span>{car_no}</span></strong>
            <figure className="photo"><img src={`../driver-img/${SF_YEAR}/${car.driver_code.replace(/[^\w]/, '_')}.png`} alt=""/></figure>
            <h4 className={(driverPrefix ? "special-name" : "normal-name")}>{driverPrefix}{driverName.split(/ /).map((s, i) => <span key={i}>{s.toUpperCase()}</span>)}</h4></div>
          <div className="auto-play">
            <input type="checkbox" className="radio-input" id={"auto-play-" + car_no} name={"auto-play-" + car_no} checked={autoPlay} onChange={e => { props.handleChangeAutoPlay(car_no, e.target.checked); }} /><label
            htmlFor={"auto-play-" + car_no} className="radio-inline">AUTO PLAY</label>
          </div>
        </div>

        { clipList && clipList.length ? (
          <>
        <div className="clip-navigation">
          <div className="row gx-4 justify-content-between">
            <div className="col">
              { currentClipListIndex > 0 && (
              <button className="block prev" onClick={() => { props.handleChangeLockOnClipIndex(car_no, currentClipListIndex - 1)}}>
                <ClipBlockInfo clip={clipList[currentClipListIndex - 1]} />
              </button>
                )}
            </div>
            <div className="col-auto">

              <div className="clip-navigation__before-after">
                <div className="row align-items-center justify-content-between">
                  <div className="col">
                    <div className="block before">
                      <ClipBlockInfo clip={clip} />
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="clip-navigation__before-after__arrow"></div>
                  </div>
                  <div className="col">
                    <div className="block after">
                      <ClipBlockInfo clip={clip} no_seq={true} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col text-end">
        { clipList && (currentClipListIndex < clipList.length - 1) && (
              <button className="block next" onClick={() => { props.handleChangeLockOnClipIndex(car_no, currentClipListIndex + 1); }}>
                <ClipBlockInfo clip={clipList[currentClipListIndex + 1]}  />
              </button>
          )}
            </div>
          </div>
        </div>

        <div className="clip-ui">
          <div className="clip-ui__waveform">
            <div id={"lockon-" + clip.car_no + "-cue"} className="base"
                 onMouseMove={e => { if (draggingState.target) { props.handleMoveLockOnCue(clip.car_no, e.clientX); }}}
                 onMouseLeave={(e) => { props.handleSetDraggingState({car_no: clip.car_no, target: null, startX: 0})}}
                 onMouseUp={(e) => { props.handleSetDraggingState({target: null, startX: 0})}}
            >
              <div  id={"lockon-" + clip.car_no + "-cue-inner"} className="base__inner">
                <span className="play-bar" style={playStateStyle}></span>
                <div className="trim-slider left" style={{transition: 'none', left: (content.cueStart - 1) * 100 + "%"}}>
                  <span
                     onMouseDown={(e) => { props.handleStartMoveLockOnCue({car_no: clip.car_no, target: 'start', startX: e.clientX})}}
                     onMouseUp={(e) => { props.handleSetDraggingState({target: null, startX: 0})}}
                  ></span>
                </div>
                <div className="trim-slider right" style={{transition: 'none', left: (content.cueEnd) * 100 + "%"}}
                        >
                  <span
                     onMouseDown={(e) => { props.handleStartMoveLockOnCue({car_no: clip.car_no, target: 'end', startX: e.clientX})}}
                     onMouseUp={(e) => { props.handleSetDraggingState({target: null, startX: 0})}}
                  ></span>


                </div>
                <img id={"lockon-" + clip.car_no + "-cue-end"} src={
                            clip.waveform_object_key ? `${getObjectStorageEndPoint()}/${clip.waveform_object_key}` : `${getEndPoint()}/autoclips/${clip.autoclip_id}/waveform`} alt="" className="waveform"/>
              </div>
            </div>
          </div>
        </div>

        <p className="clip-text">
          {clip.modified_text}
        </p>

        <div className="button-area">
          <div className="row align-items-center">
            <div className="col">
            {(loginUser.is_admin == 1 || loginUser.is_operator == 1) &&
              <button type="button" className="round-button-s edit t-hover" onClick={() => { props.handleEditOperation(clip, content); }}><span>EDIT</span></button>
            }
            </div>
              <>
                <div className="col">
                  {playState && playState.speed == 1 ? (
                    <button type="button" className="round-button-s play-button t-hover"
                            onClick={() => props.handleStopClip(clip.autoclip_id)}><span>STOP</span></button>

                  ) : (
                    <button type="button" className="round-button-s play-button t-hover"
                            onClick={() => props.handlePlayClip(clip, 1, content)}><span>PLAY</span></button>
                  )}

                </div>
              </>
            <div className="col">
                          { playState && playState.speed > 1 ? (
                          <button type="button" className="round-button-s play-button t-hover" onClick={() => props.handleStopClip(clip.autoclip_id)}>
                            <span>STOP</span></button>

                          ) : (
                          <button type="button" className="round-button-s play-button t-hover" onClick={() => props.handlePlayClip(clip, 1.5, content)}>
                            <span>×1.5&nbsp;&nbsp;PLAY</span></button>
                          )}


                        </div>
            <div className="col">
              {(loginUser.is_admin == 1 || loginUser.is_operator == 1) && !props.myCustomClips.find(c => c.autoclip_id == clip.autoclip_id) &&
                <>
                <input type="checkbox" className="radio-input lock" name={"transmit_lock-" + car_no}
                       id={"transmit_lock-" + car_no}
                       checked={locked} onChange={(e) => { console.log(e);
                  props.handleChangeLockOnClipTransmitLock(car_no, e.target.checked)
                }}/><label htmlFor={"transmit_lock-" + car_no} className="radio-inline">LOCK</label>
                </>
              }
            </div>
            <div className="col">
              {(loginUser.is_admin == 1 || loginUser.is_operator == 1) && !props.myCustomClips.find(c => c.autoclip_id == clip.autoclip_id) &&

              <button type="button" className="round-button-s transmit-button t-hover" disabled={locked} onClick={() => { props.handleDirectTransmit(clip, content); }}><span>TRANSMIT</span>
              </button>
              }
            </div>
          </div>
        </div>
        </>
) : (
        <div className="clip-waiting">
          <h3>WAITING ...</h3>
        </div>

      )}
      </div>

    </div>
  )
};
export default LockOnBlock;
