import React from "react";
import Article from "../common/Article";
import {format} from "date-fns";
import EditTextModal from "../monitoring/EditTextModal";
import ArchiveClip from "../common/ArchiveClip";
import ArchiveCustomClip from "../common/ArchiveCustomClip";

const ClipList = (props) => {
  const { archiveCar, currentCarClipList, year, roundId, carNo } = props;

  if (!(archiveCar && currentCarClipList && year && roundId && carNo)) {
    return null;
  }
  console.log(currentCarClipList)

  return (
    <>
      {props.editClip && (
        <EditTextModal
          {...props}
          editClip={props.editClip}
        />
      )}
      <div className="archive authentication">
      <Article className="content" {...props}>
        <div className="navigation">

          <h3 className="headline">AUDIO CLIP ARCHIVE</h3>


          <div className="navigation__unit">
            <div className="navigation__title-area">
              <h4>{props.year}&nbsp;&nbsp;&nbsp;&nbsp;ROUND {props.roundId}<span className="navigation__title-area__car"><strong
                className="num-box"><span>{archiveCar.car_no}</span></strong>No.{archiveCar.car_no} {archiveCar.driver_name.toUpperCase()}</span></h4></div>


            <div className="back-button-area">
              <div className="row">
                <div className="col-auto">
                  <button className="back-button t-hover" onClick={props.handleBack}><span>BACK</span></button>
                </div>
                <div className="col-auto">
                  <a href="https://superformula.net/sf3/live_timing/" target="_blank" className="round-button no-icon t-hover">
                    <span>RESULT (OFFICIAL SITE)</span></a>
                </div>
              </div>
            </div>

          </div>



          <div className="archive__list">
            {
              currentCarClipList.map((timeline, i) => {
                const openClipList = timeline.clips.filter(clip => clip.is_open);
                return timeline.clips.length ? (
                  <div className="archive__group" key={i}>
                    <h3 className="archive__category">
                      {(props.loginUser.is_admin == 1 || props.loginUser.can_edit == 1) ?
                        <a onClick={() => {
                          props.handleChangeScreen('monitoring', timeline.timeline_id);
                        }}><span>{timeline.section_name.toUpperCase()} {timeline.title}</span></a>
                        : <span>{timeline.section_name.toUpperCase()} {timeline.title}</span>
                      }
                      </h3>

                    {
                      openClipList.length ? openClipList.map((clip, j) => {
                        const customclips = timeline.customclips ? timeline.customclips.filter(cc => cc.autoclip_id == clip.autoclip_id).map(
                          (clip, k) => (
                            <ArchiveCustomClip key={k} {...props} clip={clip} />
                          )
                        ) : <></>

                        return (
                          <div key={j}>
                            <ArchiveClip {...props} clip={clip} />
                            {customclips}
                          </div>

                      )}) : (
                        <div>No open clips</div>
                      )
                    }


                  </div>
                ) : null}
              )}


            </div>
</div>
        </Article>
      </div>
    </>
  );
}

export default ClipList;
