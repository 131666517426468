import React, {useEffect, useRef, useState} from "react";
import Article from "../common/Article";
import {format} from "date-fns";

const END_POINT = (window.location.hostname === "localhost") ? 'http://localhost:8000/admin' : '/admin';

const ClipMonitor = (props) => {

  const t = props.timeline.current;
  console.log(t)
  if (!t.cars) {
    return null;
  }
  const car = t.cars.find(car => (car.car_no == props.carNo));
  console.log(props.carNo);
  console.log(car)
  return (
    <>
      <div className="monitoring authentication">
      <Article className="content" {...props}>
        <div className="navigation">

          <h3 className="headline">AUTOMATIC GENERATION AUDIO CLIP MONITORING</h3>


          <div className="navigation__unit">
            <div className="navigation__title-area">
              <h4>{t.year} ROUND {t.round_id} - {t.section_name.toUpperCase()} {t.title} ({t.capture_status.toUpperCase()})<span
                className="navigation__title-area__car"><strong className="num-box"><span>{car.car_no}</span></strong>No.{car.car_no} {car.driver_name.toUpperCase()}</span>
              </h4></div>


            <div className="back-button-area">
              <div className="row">
                <div className="col-auto">
                  <button className="back-button t-hover" onClick={props.handleBack}><span>BACK</span></button>
                </div>
                { false &&
                <div className="col-auto">
                  <button className="reload-button t-hover"><span>RELOAD</span></button>
                </div>
                  }
              </div>
            </div>



            <div className={"monitoring__list" + (t.capture_status!=="terminated" ? " active" : "")}>

              {car.clips && car.clips.map((clip, i) => {
                return (
                <div className="monitoring__list__unit" key={i}>
                <div className="row g-4 justify-content-between">
                  <div className="col">
                    <div className="info"><span className="create-time">{format(new Date(clip.start_datetime), 'HH:mm:ss.SS')}
                    </span><span className="info-test">DURATION=</span>{clip.duration_ms / 1000}s {clip.modified_text ? (
                      <><span className="info-test">TEXT=</span><small>{clip.modified_text}</small></>
                      ) : (
                        <><span className="info-test">TEXT=</span></>
                    )}
                    </div>
                    <div className="archive__unit__navi">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-auto pb-4 pb-md-0">
                          <button type="button" className="round-button-s play-button t-hover" onClick={() => { props.handlePlay(clip); }}><span>PLAY</span></button>
                        </div>
                        <div className="col-auto">
                          <button type="button" className="round-button-s red t-hover" onClick={() => { props.handleDownload(clip); }}><span>DOWNLOAD</span></button>
                        </div>
            {
              ((props.loginUser.is_admin == 1 || props.loginUser.can_download == 1) &&

            <div className="col-auto">
              <button type="button" className="round-button-s t-hover" onClick={() => { props.handleTransmit(clip); }}><span>TRANSMIT</span></button>
            </div>
              )
            }

                        {
              ((props.loginUser.is_admin == 1) &&

            <div className="col-auto">
              <audio id={"autoclip-" + clip.autoclip_id} src={END_POINT + "/autoclips/" + clip.autoclip_id + "?admin=1"} crossOrigin="use-credentials" preload={"none"}></audio>
              <button type="button" className="round-button-s play-button t-hover" onClick={() => { document.getElementById("autoclip-" + clip.autoclip_id).play(); }}><span>PLAY (iOS Compatible)</span></button>
            </div>
              )
            }

                      </div>
                    </div>

                  </div>
                  <div className="col-auto">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-auto"><h5 className="radio-title">ARCHIVE</h5></div>
                      <div className="col-auto"><input type="radio" className="radio-input red" id={"autoclip-" + clip.autoclip_id + "-open-status-show"}
                                                       name={"autoclip-" + clip.autoclip_id + "-open-status"} value="1" checked={clip.is_open} onChange={() => { props.handleOpenStatusChange(clip, true); }} /><label htmlFor={"autoclip-" + clip.autoclip_id + "-open-status-show"}
                                                                                         className="radio-inline">SHOW</label>
                      </div>
                      <div className="col-auto"><input type="radio" className="radio-input" id={"autoclip-" + clip.autoclip_id + "-open-status-hide"}
                                                       name={"autoclip-" + clip.autoclip_id + "-open-status"} value="0" checked={!clip.is_open} onChange={() => { props.handleOpenStatusChange(clip, false); }} /><label
                        htmlFor={"autoclip-" + clip.autoclip_id + "-open-status-hide"} className="radio-inline">HIDE</label></div>
                    </div>
                  </div>
                </div>
              </div>
                );

              })}

            </div>


          </div>


        </div>
      </Article>
      </div>
    </>
  );
}

export default ClipMonitor;
