import React from "react";
import TimelineMonitor from "./TimelineMonitor";
import ClipMonitor from "./ClipMonitor";

class MonitoringController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'list',
      selectedCarNo: null,
    };
  }

  componentDidMount() {

  }

  backToMenu(e) {
    e.preventDefault();
    this.props.backToMenu();
  }

  backToMonitor(e) {
    e.preventDefault();
    this.setState({selectedCarNo: null})
  }

  selectCar(carNo) {
    this.setState({selectedCarNo: carNo})
  }

  render() {
    const {selectedCarNo} = this.state;
    const {loginUser, timeline, headerAccount} = this.props;
    if (selectedCarNo) {
      return <ClipMonitor {...this.props} carNo={selectedCarNo} handleBack={e => this.backToMonitor(e)}/>;
    } else {
      return <TimelineMonitor {...this.props} handleSelectCar={carNo => this.selectCar(carNo)} handleBack={e => this.backToMenu(e)}/>;
    }
  }
}

export default MonitoringController;
